body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  cursor: default;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.epr-emoji-native {
  font-family: "Segoe UI Emoji", "Segoe UI Symbol", "Segoe UI",
  "Apple Color Emoji", "Twemoji Mozilla", "Noto Color Emoji", "EmojiOne Color",
  "Android Emoji";
  line-height: 100%;
  letter-spacing: 0;
  text-align: center;
  align-self: center;
  justify-self: center;
  display: block;
  position: relative;
}